import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery } from '@apollo/client';
import { GET_NEWS } from '@utils/queries';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import NewsDetails from '@components/news-details';
import DownloadApp from '@components/download-app';

const NewsPage = ({ newsId }) => {
  const { data } = useQuery(GET_NEWS);
  const news = data ? data.news.find(item => item.id == newsId) : {};
  const [details, setDetails] = useState({
    title: news?.title,
    content: news?.content,
  });

  useEffect(() => {
    async function loadDetails() {
      const response = await axios.get(
        `${
          process.env.CONTENT_URL
        }news/${newsId}.json?time=${new Date().getTime()}`
      );
      if (response && response.data) {
        setDetails(response.data);
      }
    }
    loadDetails();
  }, [newsId]);

  return (
    <Layout>
      <SEO
        title={`${news?.title} | الأخبار`}
        metaTitle={news?.title}
        meta={[
          {
            property: 'og:title',
            content: news?.title,
          },
          {
            property: 'og:url',
            content: typeof window !== 'undefined' ? location.href : '',
          },
          {
            property: 'og:image',
            content:
              typeof window !== 'undefined'
                ? `${location.href}/syrian-exchange/updates/${news?.image}`
                : '',
          },
        ]}
        description={news?.content}
      />
      <NewsDetails details={details} />
      <DownloadApp />
    </Layout>
  );
};

export default NewsPage;
