import React from 'react';
import { navigate } from 'gatsby';
import { Button } from '@components/styledComponents/index';
import { Content, Details, Header, Title } from './styles';

const NewsDetails = ({ details  }) => {
  return (
    <Details>
      <Header>
        <Title>{details.title}</Title>
      </Header>
      <Content>
        <div>
          <div className="news-details">
            {details.image && (
              <div className="news-image">
                <img src={`${process.env.CONTENT_URL}${details.image}`} />
              </div>
            )}
            <div>{details.content}</div>
          </div>
          {/* <br />
          <br />
          <div className="date">
            تاريخ الخبر:
            <CreateDate>
              {decodeURIComponent(createdDate)} <FiClock />
            </CreateDate>
          </div> */}
          <br />
          <br />
          <Button onClick={() => navigate('/')}>عودة</Button>
        </div>
      </Content>
    </Details>
  );
};

export default NewsDetails;
