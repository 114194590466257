import styled from 'styled-components';

export const Details = styled.div`
  box-sizing: border-box;
  padding: 10px 20px;
  direction: rtl;
`;

export const Header = styled.div`
  padding: 10px 20px;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.lightDarkBackgroundColor};
`;

export const Title = styled.h1`
  font-size: 18px;
  text-align: center;
  font-family: ${props => props.theme.fonts.arabicFont};
  padding: 10px 20px;
  border-radius: 4;
`;

export const Content = styled.div`
  padding: 12px 0;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.arabicFont};

  .date {
    display: flex;
    flex-direction: column;
    width: 140px;
  }

  .news-image {
    text-align: center;
    img {
      max-width: 100%;
      margin: 4px 0;
    }
  }

  @media (min-width: 992px) {
    .news-details {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      > div {
        flex: 1
      }
    }
    .news-image {
      margin-right: 12px;
    }
  }
`;

export const CreateDate = styled.h5`
  font-family: ${props => props.theme.fonts.englishFont};
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  margin: 12px 0 0;
  display: flex;
  align-items: center;
  direction: ltr;
  svg {
    margin-left: 4px;
  }
`;
